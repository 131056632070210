import { useState, useEffect, useCallback, useRef } from 'react'
import { useWindowEvent } from '@mantine/hooks'

export default function useHash() {
  const [hash, setHashValue] = useState("")
  const ref = useRef(hash)
  ref.current = hash
  const setHash = (value) => {
    const valueWithHash = value.startsWith("#") ? value : `#${value}`
    window.location.hash = valueWithHash
    setHashValue(valueWithHash)
  }
  useWindowEvent("hashchange", useCallback(() => {
    const newHash = window.location.hash
    if (ref.current !== newHash) {
      setHashValue(newHash)
    }
  }, []))
  useEffect(() => {
    setHashValue(window.location.hash)
  }, [])
  return [hash, setHash] as const
}

import styled from "@emotion/styled"
import { lunchbox } from "theme/fonts"
import AlertIconAsset from "app/kids/assets/status_icons/alert_icon.svg"
import MessageIconAsset from "app/kids/assets/status_icons/message_icon.svg"
import Button from "ui/Button"

export const Amount = styled.div`
  font-family: ${lunchbox.style.fontFamily};
  font-size: 37px;
  color: white;
  background-image: url(${AlertIconAsset.src});
  width: 65px;
  height: 65px;
  display: grid;
  place-items: center;
  box-sizing: border-box;
  padding-bottom: 13px;
  position: absolute;
  top: -25px;
  right: -10px;
`

export const Root = styled(Button)`
  width: 102px;
  height: 104px;
  background-image: url(${MessageIconAsset.src});
  position: fixed;
  bottom: 20px;
  right: 20px;
`

export default Root
import { ComposerToolType } from "db"

export const COMPOSER_TOOL_DISPLAY_TYPE = {
  STIPPENTOOL: 'Stippen',
  RITMETOOL: 'Ritme',
  BOOMWACKERTOOL: 'Boomwhacker',
  DRUMANDBASSTOOL: 'Drum & Bass',
}

export default function getComposerToolDisplayType(toolType: ComposerToolType) {
  return COMPOSER_TOOL_DISPLAY_TYPE[toolType]
}
import { useMutation } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value } from "@stringtale/react"
import logout from "app/auth/mutations/logout"
import { useIsKidsPreview } from "app/core/hooks/useIsKids"
import { PX14, PX32 } from "theme/sizes"
import Center from "ui/Center"
import Hr from "ui/Hr"
import { Close, Content, Overlay, Root as RootBase } from "ui/Modal"
import Stack from "ui/Stack"
import Text from "ui/Text"
import Title from "ui/Title"
import { GetCurrentKidResult } from "../queries/getCurrentKid"
import { RoundedButton } from "./RoundedButton"

const Root = styled(RootBase)`
  font-size: ${PX14};
  flex: 1;
`

const KidProfileModal = ({ kid }: { kid: GetCurrentKidResult }) => {
  const [logoutMutation] = useMutation(logout)
  const isPreview = useIsKidsPreview()
  return (
    <Dialog.Portal>
      <Dialog.Overlay asChild>
        <Overlay />
      </Dialog.Overlay>
      <Dialog.Content asChild>
        <Root width="560px">
          <Content>
            <Stack gap="40px" align="center">
              <Title size={PX32} center>
                <Value name="apps.web.src.kids.components.kidprofilemodal.mijn_profiel">
                  Mijn profiel
                </Value>
              </Title>
              <Hr />
              <Text size={PX32} center>
                {kid?.firstName}
              </Text>
              {isPreview ? null : (
                <Center>
                  <RoundedButton
                    onClick={async () => {
                      await logoutMutation()
                      sessionStorage.clear()
                    }}
                  >
                    <Value name="apps.web.src.kids.components.kidprofilemodal.uitloggen">
                      Uitloggen
                    </Value>
                  </RoundedButton>
                </Center>
              )}
            </Stack>
            <Dialog.Close asChild>
              <Close>
                <FaTimes />
              </Close>
            </Dialog.Close>
          </Content>
        </Root>
      </Dialog.Content>
    </Dialog.Portal>
  )
}

export default KidProfileModal

import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft"
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight"
import { TbMailFilled } from "@react-icons/all-files/tb/TbMailFilled"
import { TbMailOpened } from "@react-icons/all-files/tb/TbMailOpened"
import { Value } from "@stringtale/react"
import getComposerToolDisplayType from "app/composer/utils/getComposerToolDisplayType"
import useHashParam from "app/core/hooks/useHashParam"
import { prefixReopened, prefixSubmitted } from "app/core/hooks/useLinks"
import Avatar from "app/files/components/Avatar"
import getLesson from "app/slides/queries/getLesson"
import Link from "next/link"
import { useMemo } from "react"
import { LIGHT_BLUE_COLOR } from "theme/colors"
import { PX16, PX18, PX20, PX24 } from "theme/sizes"
import Button from "ui/Button"
import Center from "ui/Center"
import Group from "ui/Group"
import Hr from "ui/Hr"
import * as Modal from "ui/Modal"
import RoundedButton from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import markKidsResponseAsRead from "../mutations/markKidsResponseAsRead"
import getCurrentKid,  { GetCurrentKidResult } from "../queries/getCurrentKid"
import getKidPage from "../queries/getKidPage"
import { KIDS_MESSAGE_MODAL_KEY } from "./KidsLayout"
import { Routes } from "@blitzjs/next"

const ListItem = styled(Button)<{ isRead: boolean }>`
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  background-color: ${(p) => (p.isRead ? "#E9E9FF" : LIGHT_BLUE_COLOR)};
  color: ${(p) => (p.isRead ? "black" : "white")};
  padding: 20px 34px;
  display: flex;
  align-items: center;
`

const Comment = styled(Text)`
  white-space: pre-wrap;
`

export default function KidsMessageModal({
  kid,
}: {
  kid: GetCurrentKidResult
}) {
  const [id, setId] = useHashParam(KIDS_MESSAGE_MODAL_KEY, "number")
  const [_isOpen, setIsOpen] = useHashParam(KIDS_MESSAGE_MODAL_KEY, "boolean")
  const save = useMemo(() => {
    return kid?.saves.find((save) => save.id === id) ?? null
  }, [id, kid?.saves])
  const url =
    save && "composerToolType" in save
      ? Routes.SavedComposerToolPage({ id: save.id })
      : undefined
  // const [save, setSave] = useState<GetCurrentKid["saves"][0] | null>(null)
  const [markKidsResponseAsReadMutation] = useMutation(markKidsResponseAsRead)
  return (
    <Dialog.Portal>
      <Dialog.Overlay asChild>
        <Modal.Overlay />
      </Dialog.Overlay>
      <Dialog.Content>
        <Modal.Root width="560px">
          <Modal.Content>
            <Dialog.Close asChild>
              <Modal.Close />
            </Dialog.Close>
            {save ? (
              <Stack gap="32px">
                <Modal.Title center>
                  {save.status === "REOPENED" ? (
                    <Value name="apps.web.src.kids.components.kidsmessagemodal.opdracht_is_heropend">
                      Opdracht is heropend
                    </Value>
                  ) : (
                    <Value name="apps.web.src.kids.components.kidsmessagemodal.bericht">
                      Bericht
                    </Value>
                  )}
                </Modal.Title>
                <Stack gap="18px">
                  {save.response?.user ? (
                    <>
                      <Hr />
                      <Center>
                        <Avatar
                          user={save.response?.user}
                          width={80}
                          height={80}
                        />
                      </Center>
                    </>
                  ) : null}
                  <Hr />
                </Stack>
                <Stack gap="16px">
                  {"subject" in save ? (
                    <Stack>
                      <Text bold size={PX24}>
                        {save.subject.headSubject.group.lesson.displayTitle}
                      </Text>
                      <Text bold size={PX20}>
                        {save.subject.displayTitle}
                      </Text>
                    </Stack>
                  ) : null}
                  <Comment size={PX20}>{save.response?.comment}</Comment>
                </Stack>
                <Group justify="space-between">
                  <Modal.CancelButton
                    left={<FaChevronLeft />}
                    onClick={() => {
                      setIsOpen(true)
                    }}
                  >
                    <Value name="apps.web.src.kids.components.kidsmessagemodal.terug">
                      Terug
                    </Value>
                  </Modal.CancelButton>

                  <Modal.SubmitButton
                    right={<FaChevronRight />}
                    as={Link}
                    {...(save.status === "REOPENED"
                      ? prefixReopened(save.id, url)
                      : prefixSubmitted(save.id, url))}
                  >
                    {"composerToolType" in save ? (
                      <Value name="apps.web.src.kids.components.kidsmessagemodal.ga_naar_de_compositie">
                        Ga naar de compositie
                      </Value>
                    ) : (
                      <Value name="apps.web.src.kids.components.kidsmessagemodal.ga_naar_de_les">
                        Ga naar de les
                      </Value>
                    )}
                  </Modal.SubmitButton>
                </Group>
              </Stack>
            ) : (
              <Stack gap="32px">
                <Modal.Title center>
                  <Value name="apps.web.src.kids.components.kidsmessagemodal.inbox">
                    Inbox
                  </Value>
                </Modal.Title>
                <Stack gap="26px">
                  <Hr />
                  <Stack gap="10px">
                    {kid?.saves.map((save) =>
                      save.response ? (
                        <ListItem
                          isRead={!!save.response.isRead}
                          onClick={async () => {
                            const type = "subject" in save ? "lesson" : "studio"
                            if (save.response && !save.response?.isRead) {
                              await markKidsResponseAsReadMutation({
                                id: save.response.id,
                              })
                              await invalidateQuery(getCurrentKid)
                              await invalidateQuery(getLesson)
                              await invalidateQuery(getKidPage)
                            }
                            setId(save.id)
                          }}
                        >
                          <Stack grow align="flex-start">
                            <Group align="center" gap="16px">
                              {save.response.isRead ? (
                                <TbMailOpened size="40px" />
                              ) : (
                                <TbMailFilled size="40px" />
                              )}
                              {"subject" in save ? (
                                <Stack align="flex-start">
                                  <Text bold size={PX18}>
                                    {
                                      save.subject.headSubject.group.lesson
                                        .displayTitle
                                    }
                                  </Text>
                                  <Text size={PX16}>
                                    {save.subject.displayTitle}
                                  </Text>
                                </Stack>
                              ) : (
                                <Text bold size={PX18}>
                                  {getComposerToolDisplayType(
                                    save.composerToolType
                                  )}
                                </Text>
                              )}
                            </Group>
                          </Stack>
                          <FaChevronRight size={20} />
                        </ListItem>
                      ) : null
                    )}
                  </Stack>
                  <Center>
                    <Dialog.Close asChild>
                      <RoundedButton variant="filled">
                        <Value name="apps.web.src.kids.components.kidsmessagemodal.sluit">
                          Sluit
                        </Value>
                      </RoundedButton>
                    </Dialog.Close>
                  </Center>
                </Stack>
              </Stack>
            )}
          </Modal.Content>
        </Modal.Root>
      </Dialog.Content>
    </Dialog.Portal>
  )
}

import { useRouter } from "next/router"
import { Dispatch, SetStateAction, useMemo } from "react"
import useLinks from "./useLinks"
import useHash from "utils/hooks/useHash"

type Query = Record<string, string | undefined>

const parseHash = (hash: string): Query => {
  const query = {}
  if (hash.indexOf("#") === 0) hash = hash.substring(1)
  hash.split("&").forEach(part => {
    const [key, value] = part.split("=")
    if (!key) return
    query[key] = value
  })
  return query
}

const formatHash = (query: Record<string, string | undefined>) => {
  return Object.entries(query).flatMap(([key, value]) => {
    if (key === undefined || !value === undefined) return []
    return [`${key}=${value}`]
  }).join("&")
}


export default function useHashParam<T>(name: string): [T, Dispatch<SetStateAction<T>>]
  ; export default function useHashParam(name: string, type?: "string"): [string | null, Dispatch<SetStateAction<string | null>>]
  ; export default function useHashParam(name: string, type: "number"): [number, Dispatch<SetStateAction<number>>]
  ; export default function useHashParam(name: string, type: "boolean"): [boolean, Dispatch<SetStateAction<boolean>>]

  ; export default function useHashParam(name: string, type: "string" | "number" | "boolean" = "string") {

    const [hash, setHash] = useHash()
    const query = useMemo(() => parseHash(hash), [hash])

    const value = useMemo(() => {
      let value: any = query[name]
      if (Array.isArray(value)) {
        value = value[0]
      }
      if (type === "number") {
        return parseInt(value)
      }
      if (type === "boolean") {
        return value !== undefined
      }
      return value
    }, [name, query, type])

    return [value, (newValueOrFunction: SetStateAction<any>) => {
      if (typeof newValueOrFunction === "function") {
        newValueOrFunction = newValueOrFunction(value)
      }
      let newQuery: Query = { ...query }
      // Remove key starting with __
      Object.keys(newQuery).forEach(key => {
        if (key.startsWith("__")) {
          delete newQuery[key]
        }
      })
      if (type === "boolean") {
        if (newValueOrFunction === false) {
          delete newQuery[name]
        } else {
          newQuery = {
            ...newQuery,
            [name]: "",
          }
        }
      } else {
        if (newValueOrFunction === null) {
          delete newQuery[name]
        } else {
          newQuery = {
            ...newQuery,
            [name]: newValueOrFunction ? String(newValueOrFunction) : newValueOrFunction,
          }
        }
      }
      if (newQuery.host) {
        delete newQuery.host
      }

      setHash(formatHash(newQuery))
      // router.push(getLink({
      //   pathname: router.pathname,
      //   query: newQuery,
      // }))
    }] as const
  }
